$(function () {
  $('.mobile-nav-trigger').on('click', function () {
    $(this).toggleClass('active');
    $(this).find('i').toggleClass('fa-bars').toggleClass('fa-times');
    $('nav.nav').toggleClass('mobile-menu-open');
  });

  $('.nav .menu-item-has-children span').on('click', function(e){
      if( $(this).parent('.menu-item-has-children').hasClass('open') ) {
          $(this).parent().find('.sub-menu').slideUp('fast');
          $(this).parent('.menu-item-has-children').removeClass('open');
      } else {
          $('.nav li').find('.sub-menu').slideUp('fast');
          $('.nav li').removeClass('open');

          $(this).parent().find('.sub-menu').slideToggle('fast');
          $(this).parent().toggleClass('open');
      }
  });

  //accordion logic
  $('.a-header').on('click', function () {
    //if user clicked on an open accordion
    if ($(this).hasClass('open')) {
      $(this).removeClass('open');
      $(this).next().slideUp();
      $(this).find('.toggle-icon').removeClass('fa-minus').addClass('fa-plus');
    }
    //user clicked on closed accordion
    else {
      //setup some vars to make life easy peezy
      let $thisH = $(this);
      let $thisB = $(this).next();
      let $thisI = $(this).find('.toggle-icon');

      //close all open accords
      $('.accordion .a-body').slideUp();
      $('.accordion .a-header').removeClass('open');
      $('.accordion .a-header .toggle-icon').removeClass('fa-minus').addClass('fa-plus');

      //open the clicked accord
      $thisB.slideDown();
      $thisH.addClass('open');
      $thisI.removeClass('fa-plus').addClass('fa-minus');
    }
  });
  //open the first accordion on load
  $('.a-header').eq(0).trigger('click');

  //check if #sticky-sidebar exists
  if ($('#sticky-sidebar').length > 0) {
    let stickyHeader = new Sticky('#sticky-sidebar');
  }
});


// scrollable tables and image/text blocks
$('document').ready(function(){
    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table, .table-flex').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before( '<div class="scroll-header">Swipe to view more content</div>' );
    }
    scrollWrap();

    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table, .table-flex').each(function(index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
                table.parent().addClass('state-overflow');
            } else {
                // element doesn't overflow
                scrollHeader.hide();
                table.parent().removeClass('state-overflow');
            }
        })
    }
    $(window).on("resize",function () {
        handleScrollResize();
    });
    $(document).ready( function () {
        handleScrollResize();
    });
});
